<template>
  <TableViewWithTitle title="파트너사 정보">
    <template v-slot:body>
      <tr>
        <th>업체명</th>

        <td>
          <button
            class="link_subject"
            @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${model.userNum}`)"
          >
            {{ (tenderPartner && tenderPartner.companyName) || "" }}
          </button>
        </td>
        <th>대표이사명</th>
        <td>{{ (tenderPartner && tenderPartner.ceo) || "" }}</td>
      </tr>
      <tr>
        <th>사업자번호</th>
        <td colspan="3">
          {{ (tenderPartner && tenderPartner.corporateNum) || "" }}
        </td>
      </tr>
      <tr>
        <th>본사 주소</th>
        <td colspan="3">
          {{ (tenderPartner && tenderPartner.address) || "" }}
          {{ (tenderPartner && tenderPartner.address2) || "" }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

export default {
  name: "SupplyPartnerInfoInput",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    model: Object,
  },
  computed: {
    tenderPartner() {
      if (!this.model) return null;
      return this.model.tenderPartner;
    },
    estimateFileList() {
      if (!this.model || !this.model.estimateFile) return [];

      return [this.model.estimateFile];
    },
  },
};
</script>
