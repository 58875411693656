<template>
  <div class="box_oath">
    <div class="line_info">
      <dl>
        <dt>파트너사</dt>
        <dd>{{ (model && model.to.companyName) || "" }}</dd>
      </dl>
      <dl>
        <dt>사업자 번호</dt>
        <dd>{{ (model && model.to.corporateNum) || "" }}</dd>
      </dl>
      <dl>
        <dt>대표이사</dt>
        <dd>{{ (model && model.to.ceo) || "" }}</dd>
      </dl>
      <dl>
        <dt>주소</dt>
        <dd>{{ (model && model.to.address) || "" }}</dd>
      </dl>
    </div>
    <span class="bar_info" />
    <div class="line_info">
      <dl>
        <dt>현업 담당자</dt>
        <dd>{{ (model && model.from.bManagerName) || "" }}</dd>
      </dl>
      <dl>
        <dt>연락처</dt>
        <dd>{{ (model && model.from.bManagerTel) || "" }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderPrintOath",
  props: {
    model: Object,
  },
};
</script>
