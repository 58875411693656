<template>
  <TableViewWithTitle title="발주 정보">
    <template v-slot:body>
      <tr>
        <th>구매요청서 연결</th>
        <td colspan="3">
          <ConnectViewOnlyOne
            :dataList="itemData.purchaseWfOrder"
            :isDisabled="itemData.purchaseWfOrder && itemData.purchaseWfOrder.aprvlStatus == '-'"
            @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
          />
        </td>
      </tr>
      <tr v-if="estimateContract">
        <th>견적서 연결</th>
        <td colspan="3">
          <ConnectViewOnlyOne
            :dataList="estimateContract"
            @onClickConnect="(item) => $emit('onClickPopEstimatePrint', item)"
          />
        </td>
      </tr>
      <tr v-if="itemData.contractId">
        <th>계약 정보</th>
        <td colspan="3">
          <ConnectViewOnlyOne
            :dataList="{ contractName: itemData.contractName, contractId: itemData.contractId }"
            titleKeyName="contractName"
            :isDisabled="itemData.contractId == ''"
            @onClickConnect="(item) => $emit('onClickConnectContract', item)"
          />
        </td>
      </tr>
      <tr>
        <th>프로젝트코드</th>
        <td>{{ itemData.projectCode }}</td>
        <th>프로젝트명</th>
        <td v-html="itemData.projectName"></td>
      </tr>
      <tr>
        <th>발주서명</th>
        <td colspan="3" class="td_text" v-html="itemData.title"></td>
      </tr>
      <tr>
        <th>발주서 템플릿</th>
        <td>{{ templateName }}</td>
        <th>계약기간</th>
        <td>
          {{ itemData.orderStartDate | dateStringFormat }} ~
          {{ itemData.orderEndDate | dateStringFormat }}
        </td>
      </tr>
      <tr>
        <th>계약 체결일</th>
        <td>{{ itemData.orderDate | dateStringFormat }}</td>
        <th>납품기한</th>
        <td>{{ itemData.goodsDeadline | dateStringFormat }}</td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";
import ConnectView from "@/components/common/connect/ConnectView";

import { getDateString } from "@/utils/dateUtils";

export default {
  name: "OrderInfo",
  components: {
    TableViewWithTitle,
    ConnectViewOnlyOne,
    ConnectView,
  },
  props: {
    type: String,
    orderCid: String,
    itemData: Object,
    templateName: String,

    estimateContract: Object,
  },
  computed: {},
};
</script>
