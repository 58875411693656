<template>
  <TableViewWithTitle title="구매신청서 연결">
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col />
    </template>
    <template v-slot:body>
      <tr>
        <th>구매신청서 연결</th>
        <td>
          <ConnectView
            :dataList="connectDataList"
            @onClickDetail="onClickDetail"
            @onClickClose="onClickClose"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ConnectView from "@/components/common/connect/ConnectView";

export default {
  components: {
    TableViewWithTitle,
    ConnectView,
  },
  props: {
    connectDataList: Array,
  },
  methods: {
    onClickDetail(id) {
      this.$emit("onClickDetail", id);
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
