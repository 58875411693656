<template>
  <div class="area_file">
    <button
      v-for="(item, index) in dataList"
      :key="index"
      type="button"
      class="btn_file"
      @click="onClick(item)"
    >
      {{ `[별첨${index + 1}] ${item.orgFile}` }}
    </button>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "AttachedFileView",
  props: {
    dataList: Array,
  },
  methods: {
    async onClick(item) {
      const path = `${this.$apiPath.ATTACH_FILE}/${item.sysFilePath}`;

      const result = await ApiService.shared.getFile(path);

      this.downloadFile(result, item);
    },
    downloadFile(blob, item) {
      var newBlob = new Blob([blob]);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = item.orgFile || "download";
      link.click();
    },
  },
};
</script>
<style scoped>
.area_file .btn_file {
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: #5551ce;
  text-decoration: underline;
  text-align: left;
}
.area_file .btn_file + .btn_file {
  margin-top: 8px;
}
</style>
