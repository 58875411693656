<template>
  <div class="area_view">
    <TableHead title="납품 정보" />
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col />
      </template>
      <template v-slot:body>
        <tr>
          <th>대금 지급 방법</th>
          <td class="td_text" v-html="(model && model.payMethod) || ''" />
        </tr>
        <tr>
          <th>대금 지급 조건</th>
          <td class="td_text" v-html="(model && model.payCondition) || ''" />
        </tr>
        <tr>
          <th>납품 장소</th>
          <td class="td_text" v-html="(model && model.goodsPlace) || ''" />
        </tr>
        <tr>
          <th>계약&#47;하자 보증금</th>
          <td class="td_text" v-html="(model && model.deposit) || ''" />
        </tr>
        <tr>
          <th>기타</th>
          <td class="td_text" v-html="(model && model.otherTerms) || ''" />
        </tr>
      </template>
    </TableView>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import TableView from "@/components/shared/tableView/TableView";

export default {
  name: "PaymentInfo",
  components: {
    TableHead,
    TableView,
  },
  props: {
    model: Object,
  },
};
</script>
