<template>
  <Popup popupType="printType">
    <div class="section_comm">
      <div class="body_section">
        <div class="box_print">
          <div id="wrapPrint" class="wrap_print">
            <OrderPrintLevel1
              v-bind="propsToPass"
              :orderPrintInfoModel="orderPrintInfoModel"
              :orderPrintTotalModel="orderPrintTotalModel"
              :paymentInfoModel="paymentInfoModel"
              :supplyInfoModelList="supplyInfoModelList"
              :title="title"
              :currency="currency"
            />
            <OrderPrintLevel2 v-bind="propsToPass" :adminCompanyName="adminCompanyName" />
            <OrderPrintLevel3 v-bind="propsToPass" />
            <OrderPrintLevel4
              :isShowAgreeButton="false"
              :orderPrintOath="orderPrintOath"
              v-bind="propsToPass"
              :attachFileList="attachFileList"
            />
          </div>
        </div>
      </div>
    </div>
    <Sticky class="sticky">
      <button type="button" class="btn_fourthly btn_large" @click="onClickClose">닫기</button>
      <button v-if="isPrintAble" type="submit" class="btn_tertiary btn_large" @click="print">
        인쇄하기
      </button>
    </Sticky>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import Sticky from "@/components/layout/content/Sticky.vue";

import OrderPrintLevel1 from "@/components/shared/print/OrderPrintLevel1.vue";
import OrderPrintLevel2 from "@/components/shared/print/OrderPrintLevel2.vue";
import OrderPrintLevel3 from "@/components/shared/print/OrderPrintLevel3.vue";
import OrderPrintLevel4 from "@/components/shared/print/OrderPrintLevel4.vue";

import { Printd } from "printd";
import printcss from "@/constants/printcss.js";

export default {
  name: "OrderPrintPopup",
  components: {
    Popup,
    Sticky,
    OrderPrintLevel1,
    OrderPrintLevel2,
    OrderPrintLevel3,
    OrderPrintLevel4,
  },
  props: {
    adminCompanyName: String,
    isPrintAble: Boolean,
    orderNumber: String,

    orderPrintInfoModel: Object,
    orderPrintTotalModel: Object,
    paymentInfoModel: Object,
    supplyInfoModelList: Array,
    orderPrintOath: Object,
    title: String,
    currency: String,
    attachFileList: Array,
  },
  computed: {
    propsToPass() {
      return {
        currentPage: this.currentPage,
        totalPageCount: this.totalPageCount,
        orderNumber: this.orderNumber,
      };
    },
  },
  methods: {
    print() {
      const wrapPrint = document.getElementById("wrapPrint");
      const d = new Printd();

      // printd 라이브러리 버그로 인해 그냥 스트링을 가져오는 걸로 임시 처리. 추후 변경 필요.
      // 버그 내용: css를 읽었다 안읽었다 한다.
      // const styles = [
      //   '/assets/css/print.css'
      // ]

      const styles = [printcss];

      d.print(wrapPrint, styles);
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/css/print.css";
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}
</style>
