<template>
  <Fold title="대금조건">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>대금 청구 조건</th>
          <td>
            [{{ computedPaymentType }}]
            {{ orderPayment.paymentDescription | nullToDash }}
          </td>
          <th>대금 지급 조건</th>
          <td>{{ orderPayment.executionCondition | nullToDash }}</td>
        </tr>
      </template>
    </TableView>

    <TableViewWithTitle v-if="orderPayment.paymentType === 'D'" style="margin-top: 10px">
      <template v-slot:colgroup>
        <!-- No -->
        <col style="width: 40px" />
        <!-- 구분 -->
        <col style="width: 152px" />
        <!-- 지급비율 -->
        <col style="width: 428px" />
        <!-- 지급금액 -->
        <col />
      </template>
      <template v-slot:header>
        <th scope="col">No.</th>
        <th scope="col">구분</th>
        <th scope="col">지급비율(%)</th>
        <th scope="col">지급금액</th>
      </template>
      <template v-slot:body>
        <tr v-for="item in orderPayment.orderPartialPayments" :key="item.partialNum">
          <td>{{ item.orderNum }}</td>
          <td>{{ billingText(item.billingType) }}</td>
          <td class="align_right">{{ item.rate }}</td>
          <td class="align_right">{{ item.amount | currency }}</td>
        </tr>
      </template>
    </TableViewWithTitle>
    <TableViewWithTitle>
      <template v-slot:colgroup>
        <!-- 비율문구 -->
        <col style="width: 124px" />
        <!-- 비율 -->
        <col style="width: 80px" />
        <!-- 보증방법 -->
        <col style="width: 80px" />
        <!-- 보증방법 -->
        <col style="width: 160px" />
        <!-- 증권번호문구 -->
        <col style="width: 150px" />
        <!-- 증권번호 -->
        <col style="width: 248px" />
        <!-- 첨부파일문구 -->
        <col style="width: 150px" />
        <!-- 첨부파일 -->
        <col style="width: 248px" />
      </template>
      <template v-slot:body>
        <tr>
          <th>선급금보증비율(%)</th>
          <td class="align_right">
            {{ orderPayment.advanceRate | nullToDash }}
          </td>
          <th>보증방법</th>
          <td>{{ guaranteeText(orderPayment.advancePaymentGuaranteeType) }}</td>
          <th>선급금보증증권 번호</th>
          <td>{{ orderPayment.advancePaymentSurety | nullToDash }}</td>
          <th>선급금보증증권 첨부파일</th>
          <td>
            <FileView :dataList="attachFile.advancePaymentSuretyAttachFile || []" />
          </td>
        </tr>
        <tr>
          <th>계약보증비율(%)</th>
          <td class="align_right">{{ orderPayment.contractRate | nullToDash }}</td>
          <th>보증방법</th>
          <td>{{ guaranteeText(orderPayment.contractGuaranteeType) }}</td>
          <th>계약이행증권 번호</th>
          <td>{{ orderPayment.contractSurety | nullToDash }}</td>
          <th>계약이행증권 첨부파일</th>
          <td><FileView :dataList="attachFile.contractSuretyAttachFile || []" /></td>
        </tr>
        <tr>
          <th>하자보증비율(%)</th>
          <td class="align_right">{{ orderPayment.defectRate | nullToDash }}</td>
          <th>보증방법</th>
          <td>{{ guaranteeText(orderPayment.defectGuaranteeType) }}</td>
          <th>하자이행증권 번호</th>
          <td>{{ orderPayment.defectSurety | nullToDash }}</td>
          <th>하자이행증권 첨부파일</th>
          <td><FileView :dataList="attachFile.defectSuretyAttachFile || []" /></td>
        </tr>
        <tr>
          <th>지체상금</th>
          <td colspan="3">
            <strong>{{ orderPayment.penaltyRate }}</strong> / 1000
          </td>
          <th>하자보증기간</th>
          <td>
            <strong>{{ orderPayment.warranty }}</strong> 개월
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </TableViewWithTitle>
    <!-- <TableView>
      <template v-slot:body>
        <tr>
          <th>계약금 지급 비율</th>
          <td>{{ itemData.downPaymentRate | percentValue }}</td>
          <th>계약금 지급 금액</th>
          <td>
            {{ itemData.downPayment ? itemData.currencyCode : '' }}
            {{ itemData.downPayment || '' }}
          </td>
        </tr>
        
        <tr>
          <th>중도금 지급 비율</th>
          <td>{{ itemData.middlePaymentRate | percentValue }}</td>
          <th>중도금 지급 금액</th>
          <td>
            {{ itemData.middlePayment ? itemData.currencyCode : '' }}
            {{ itemData.middlePayment || '' }}
          </td>
        </tr>
        <tr>
          <th>잔금 지급 비율</th>
          <td>{{ itemData.balanceRate | percentValue }}</td>
          <th>잔금 지급 금액</th>
          <td>
            {{ itemData.balance ? itemData.currencyCode : '' }}
            {{ itemData.balance || '' }}
          </td>
        </tr>
        
        <tr>
          <th>계약보증 비율</th>
          <td>{{ itemData.contractBondRate | percentValue }}</td>
          <th>하자보증 비율</th>
          <td>{{ itemData.defactBondRate | percentValue }}</td>
        </tr>
        <tr>
          <th>선급금이행증권</th>
          <td>{{ itemData.advancePaymentSurety | nullToDash }}</td>
          <th>선급금이행증권 첨부파일</th>
          <td>
            <FileView :dataList="itemData.advancePaymentSuretyAttachFile || []" />
          </td>
        </tr>
        <tr>
          <th>계약이행증권</th>
          <td>{{ itemData.contractSurety | nullToDash }}</td>
          <th>계약이행증권 첨부파일</th>
          <td>
            <FileView :dataList="itemData.contractSuretyAttachFile || []" />
          </td>
        </tr>
        <tr>
          <th>하자이행증권</th>
          <td>{{ itemData.defectSurety | nullToDash }}</td>
          <th>하자이행증권 첨부파일</th>
          <td>
            <FileView :dataList="itemData.defectSuretyAttachFile || []" />
          </td>
        </tr>
      </template>
    </TableView> -->
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

export default {
  name: "PaymentMethod",
  components: {
    Fold,
    TableView,
    TableViewWithTitle,
    FileView,
  },
  props: {
    orderPayment: Object,
    attachFile: Object,
  },
  computed: {
    computedPaymentType() {
      const paymentType = this.orderPayment.paymentType;
      let ret = "";
      switch (paymentType) {
        case "D":
          ret = "분할지급";
          break;
        case "R":
          ret = "기성지급";
          break;
        default:
          ret = "";
          break;
      }
      return ret;
    },
  },
  methods: {
    billingText(billingType) {
      let ret = "";
      switch (billingType) {
        case "D":
          ret = "선급금";
          break;
        case "S":
          ret = "중도금";
          break;
        case "B":
          ret = "잔금";
          break;
        default:
          ret = "잔금";
          break;
      }
      return ret;
    },
    guaranteeText(guaranteeType) {
      let ret = "";
      switch (guaranteeType) {
        case "G":
          ret = "보증보험증권";
          break;
        case "M":
          ret = "각서";
          break;
        case "E":
          ret = "보증증권면제";
          break;
        default:
          ret = "-";
          break;
      }
      return ret;
    },
  },
};
</script>
