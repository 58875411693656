<template>
  <div class="area_term">
    <strong class="tit_term">제 18 조 (고객정보 보호의 의무)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”는 본 발주 이행과 관련하여 알게된 “회사”의 고객정보(고객의 성명, 주민등록번호,
        이동전화번호, 주소, 연락처, 단말기정보 등 당해 고객을 알아볼 수 있거나, 다른 정보와 결합하여
        용이하게 알아볼 수 있는 부호, 문자, 사진 등의 정보 및 통화내역 등 고객의 통신비밀 그리고
        위치정보, 신용정보 등 관련 법령에 규정된 개인정보 등을 통틀어서 말한다. 이하 “고객정보”)를
        보호할 의무를 부담한다. “파트너사”는 “회사”의 “고객정보”를 본 발주의 이행에 필요한 업무
        이외의 용도로 이용하거나 제3자에게 제공 또는 누설하지 않으며, 기타 “고객정보”를 침해하는
        행위를 하여서는 아니 된다.
      </li>
      <li>
        ② “파트너사”는 위탁업무를 수행하면서 알게 된 “회사”의 “고객정보”를 발주를 이행하기 위한
        최소한의 업무 목적 외에 컴퓨터 파일, 문서 등의 형태로 별도로 보관해서는 아니 되며, 이를
        위반하여 “회사”의 “고객정보” 침해가 발생할 경우 “파트너사”는 이에 대한 모든 책임을 부담한다.
      </li>
      <li>
        ③ “파트너사”는 자신이 고용한 직원이 “회사”의 “고객정보” 보호의무를 준수하도록
        관리ㆍ감독하여야 하며, 그 직원이 이를 위반한 경우 그에 대한 모든 책임을 진다.
      </li>
      <li>
        ④ “파트너사”는 “회사”가 제시하는 “고객정보” 보호를 위한 제반 사항을 적극 이행하여야 하며,
        이행 사항에 대한 점검 또는 감사를 요구할 경우 적극 이에 협조하여야 한다.
      </li>
      <li>
        ⑤ “파트너사”는 본 발주일반계약에서 정한 “회사”의 “고객정보” 보호의무와는 별도로 개인정보
        보호 관련 법령이 요구하는 각종 의무를 준수하여야 하며, 이를 위반할 경우 그에 따른 모든
        책임을 진다.
      </li>
    </ul>
    <strong class="tit_term">제 19 조 (발주의 해석 및 관할 법원)</strong>
    <ul class="list_term">
      <li>
        ① 본 발주에서 정하지 아니한 사항이나 본 발주 내용의 해석에 이견이 있는 경우에는 당사자간의
        별도 합의 또는 관련 법규에 의한다.
      </li>
      <li>
        ② 본 발주와 관련하여 분쟁이 발생하는 경우에는 서울중앙지방법원을 제1심 관할법원으로
        합의한다.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OrderPrintTerm03",
};
</script>
