<template>
  <TableViewWithTitle title="납기일 변경 내역">
    <template v-slot:colgroup>
      <col style="width: 120px" />
      <col />
      <col style="width: 100px" />
      <col style="width: 120px" />
      <col style="width: 160px" />
      <col style="width: 400px" />
    </template>
    <template v-slot:header>
      <th>변경요청일</th>
      <th>변경사유</th>
      <th>승인여부</th>
      <th>승인/거절 일시</th>
      <th>승인자</th>
      <th>승인/거절 사유</th>
    </template>
    <template v-slot:body>
      <GoodsDeadlineHistoryLine
        v-for="(item, index) in dataList"
        :key="item.reqId"
        :orderCid="orderCid"
        :item="item"
        :index="index"
        @getOrderData="getOrderData"
      />
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

import GoodsDeadlineHistoryLine from "@/components/admin/order/view/GoodsDeadlineHistoryLine.vue";

import { escapeRegExp } from "@/utils/spUtils";
export default {
  name: "GoodsDeadlineHistory",
  components: {
    TableViewWithTitle,
    FileView,
    GoodsDeadlineHistoryLine,
  },
  props: {
    orderCid: String,
    dataList: Array,
  },
  computed: {},
  methods: {
    agreeText(bool) {
      let ret = "대기";
      if (bool === "") {
        ret = "대기";
      } else if (bool) {
        ret = "승인";
      } else {
        ret = "거절";
      }
      return ret;
    },
    getOrderData() {
      this.$emit("getOrderData");
    },
  },
};
</script>
<style lang="scss" scoped>
.icons-deadline {
  display: block;

  font-size: 18px;
}
</style>
