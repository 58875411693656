<template>
  <Popup popupType="tableType" width="824px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">사유</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 608px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>사유<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Textarea
                  :value.sync="reasonSync"
                  :maxLength="300"
                  :isCount="true"
                  placeholder="사유를 입력하세요."
                />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button type="submit" class="btn_secondary btn_medium" @click="onClickSubmit">확인</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import Textarea from "@/components/common/textarea/Textarea";
import PageMixin from "@/mixins/PageMixin";

export default {
  name: "OrderReasonPopup",
  components: {
    Popup,
    TableView,
    Textarea,
  },
  mixins: [PageMixin],
  props: {
    changeReason: String,
  },
  computed: {
    reasonSync: {
      get() {
        return this.changeReason;
      },
      set(value) {
        this.$emit("update:changeReason", value);
      },
    },
  },
  methods: {
    // 취소 버튼
    onClickClose() {
      this.$emit("onClickReasonClose");
    },
    // 확인 버튼
    onClickSubmit() {
      this.$emit("onClickReasonSubmit");
    },
  },
};
</script>
