<template>
  <!-- 상단 고정 발주 번호 -->
  <div class="area_number">
    <div class="badge_number">
      <strong class="tit_number">발주 번호</strong>
      <span class="txt_number">{{ orderNumber }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderPrintHeader",
  props: {
    orderNumber: String,
  },
};
</script>
