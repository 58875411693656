<template>
  <TableViewWithTitle title="파트너사 담당자 정보">
    <template v-slot:body>
      <tr>
        <th>파트너사 담당자</th>
        <td colspan="3">
          {{ (itemData && itemData.name) || "" }}
        </td>
      </tr>
      <tr>
        <th>휴대전화</th>
        <td>{{ (itemData && itemData.mobile) || "" }}</td>
        <th>이메일</th>
        <td class="td_text">
          {{ (itemData && itemData.email) || "" }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "SupplyPartnerManagerInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    tenderPersonList: Array,
  },
  computed: {
    itemData() {
      // 담당자가 배열로 들어오는데 한명만 노출.
      if (!this.tenderPersonList || this.tenderPersonList.length < 1) return null;

      return this.tenderPersonList[0];
    },
  },
};
</script>
