<template>
  <div class="group_info">
    <div class="area_info_company">
      <div class="info_left">
        <span class="bar_info" />
        <strong class="tit_info">From. {{ (model && model.from.companyName) || "" }}</strong>
        <div class="group_info">
          <dl>
            <dt>사업자번호</dt>
            <dd>{{ (model && model.from.corporateNum) || "" }}</dd>
          </dl>
          <dl>
            <dt>대표이사</dt>
            <dd>
              {{ (model && model.from.ceo) || ""
              }}<span v-if="model && model.from.ceo" class="txt_sign">(인)</span>
            </dd>
          </dl>
        </div>
        <div class="group_info">
          <dl>
            <dt>주소</dt>
            <dd>{{ (model && model.from.address) || "" }}</dd>
          </dl>
          <dl>
            <dt>구매 담당자&#47;연락처</dt>
            <dd>{{ (model && model.from.pManagerName) || "" }}</dd>
            <dd>{{ (model && model.from.pManagerTel) || "" }}</dd>
          </dl>
          <dl>
            <dt>현업 담당자&#47;연락처</dt>
            <dd>{{ (model && model.from.bManagerName) || "" }}</dd>
            <dd>{{ (model && model.from.bManagerTel) || "" }}</dd>
          </dl>
        </div>
      </div>
      <div class="info_right">
        <strong class="tit_info">To. {{ (model && model.to.companyName) || "" }}</strong>
        <div class="group_info">
          <dl>
            <dt>사업자 번호</dt>
            <dd>{{ (model && model.to.corporateNum) || "" }}</dd>
          </dl>
          <dl>
            <dt>대표이사</dt>
            <dd>
              {{ (model && model.to.ceo) || ""
              }}<span v-if="model && model.to.ceo" class="txt_sign">(인)</span>
            </dd>
          </dl>
        </div>
        <div class="group_info">
          <dl>
            <dt>주소</dt>
            <dd>{{ (model && model.to.address) || "" }}</dd>
          </dl>
          <dl>
            <dt>파트너사 담당자&#47;연락처</dt>
            <dd>{{ (model && model.to.managerName) || "" }}</dd>
            <dd>{{ (model && model.to.managerMobile) || "" }}</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="area_info">
      <div class="info_left">
        <span class="bar_info" />
        <dl>
          <dt>발주 일자</dt>
          <dd>{{ (model && model.orderDate) || "" }}</dd>
        </dl>
        <dl>
          <dt>납품 기한</dt>
          <dd>{{ (model && model.goodsDeadline) || "" }}</dd>
        </dl>
      </div>
      <div class="info_right">
        <dl class="box_total">
          <dt>합계 금액</dt>
          <dd v-html="sumPrice" />
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { addCommaForMoney } from "@/utils/stringUtils";
// import { getCountWithCurrency } from '@/utils/spUtils'

export default {
  name: "OrderPrintInfo",
  props: {
    model: Object,
    currency: String,
  },
  computed: {
    sumPrice() {
      if (!this.model) return "";

      const result =
        addCommaForMoney(this.model.sumPrice) +
        ' <span class="txt_util">' +
        this.currency +
        "</span>";
      return result;
    },
  },
  methods: {
    addCommaForMoney(value) {
      if (!value) return "";

      return addCommaForMoney(String(value));
    },
  },
};
</script>
