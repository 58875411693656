<template>
  <div class="area_tip">
    <div class="tbl_print tbl_view">
      <table>
        <caption />
        <colgroup>
          <col style="width: 216px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>대금 지급 방법</th>
            <td v-html="(model && model.payMethod) || ''" />
          </tr>
          <tr>
            <th>대급 지급 조건</th>
            <td v-html="(model && model.payCondition) || ''" />
          </tr>
          <tr>
            <th>납품 장소</th>
            <td v-html="(model && model.goodsPlace) || ''" />
          </tr>
          <tr>
            <th>계약&#47;하자 보증금</th>
            <td v-html="(model && model.deposit) || ''" />
          </tr>
          <tr>
            <th>기타</th>
            <td v-html="(model && model.otherTerms) || ''" />
          </tr>
        </tbody>
      </table>
    </div>
    <p class="desc_alert">
      발주서, 발주일반계약 확인 후 공정 투명거래 서약서 하단 동의버튼 클릭 바라며, 별도의견 있으실
      경우 3일 내 의견 전달 바랍니다.
    </p>
  </div>
</template>

<script>
export default {
  name: "OrderPrintTip",
  props: {
    model: Object,
  },
};
</script>
