<template>
  <TableViewWithTitle title="담당자 정보">
    <template v-slot:colgroup>
      <col style="width: 120px" />
      <col style="width: 294px" />
      <col style="width: 120px" />
      <col style="width: 294px" />
      <col style="width: 120px" />
      <col style="width: 294px" />
    </template>
    <template v-slot:header>
      <tr>
        <th colspan="2">구매 담당자</th>
        <th colspan="2">구매 요청자</th>
        <th colspan="2">검수 담당자</th>
      </tr>
    </template>
    <template v-slot:body>
      <tr>
        <th>구매 담당자</th>
        <td>{{ `${pManager.name} (${pManager.loginId})` }}</td>
        <th>구매 요청자</th>
        <td>{{ `${bManager.name} (${bManager.loginId})` }}</td>
        <th>검수 담당자</th>
        <td>
          <template v-if="$isAdmin && (status === 'T' || status === 'C' || status === 'O')">
            <div class="group_form">
              <button type="button" class="btn_fourthly btn_small" @click="onClickPopLdap">
                조직도 검색
              </button>
              <Input
                style="width: 170px"
                placeholder="조직도 아이디를 입력하세요"
                :isDisabled="true"
                :value.sync="computedImanager"
              />
              <button class="btn_tertiary btn_small" @click="chnageIManagerConfirm">
                검수담당자변경
              </button>
            </div>
          </template>
          <template v-else>{{ `${iManager.name} (${iManager.loginId})` }}</template>
        </td>
      </tr>
      <tr>
        <th>전화번호 (내선)</th>
        <td>{{ pManager.tel | nullToDash }}</td>
        <th>전화번호 (내선)</th>
        <td>{{ bManager.tel | nullToDash }}</td>
        <th>전화번호 (내선)</th>
        <td>{{ iManager.tel | nullToDash }}</td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>{{ pManager.email | nullToDash }}</td>
        <th>이메일</th>
        <td>{{ bManager.email | nullToDash }}</td>
        <th>이메일</th>
        <td>{{ iManager.email | nullToDash }}</td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import manager from "@/store/modules/manager/manager";
import Input from "@/components/common/input/Input";

import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
    Input,
  },
  props: {
    orderCid: String,
    status: String,
    purchasePersonList: Array,
  },
  data() {
    return {
      managerObj: { code: "", email: "", loginId: "", name: "", personNum: 0, tel: "" },
    };
  },
  computed: {
    pManager() {
      let ret = _.cloneDeep(this.managerObj);
      if (this.purchasePersonList.some((item) => item.code === "P")) {
        ret = this.purchasePersonList.find((item) => item.code === "P");
      }
      return ret;
    },
    bManager() {
      let ret = _.cloneDeep(this.managerObj);
      if (this.purchasePersonList.some((item) => item.code === "B")) {
        ret = this.purchasePersonList.find((item) => item.code === "B");
      }
      return ret;
    },
    iManager() {
      let ret = _.cloneDeep(this.managerObj);
      if (this.purchasePersonList.some((item) => item.code === "I")) {
        ret = this.purchasePersonList.find((item) => item.code === "I");
      }
      return ret;
    },
    computedImanager() {
      const { name, loginId } = this.iManager;

      if (!name && !loginId) {
        return "";
      }

      return `${name} (${loginId})`;
    },
  },
  methods: {
    onClickPopLdap() {
      this.$emit("onClickPopLdap");
    },
    chnageIManagerConfirm() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: "검수담당자를 변경하시겠습니까?",
        onClickY: () => {
          this.chnageIManager();
        },
      });
    },
    async chnageIManager() {
      const path = this.$apiPath.ORDER_MANAGER;
      const result = await ApiService.shared.putData(
        `${path}/${this.orderCid}/${this.iManager.loginId}`,
      );
      const { code, data, text } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, text);
      }
    },
  },
};
</script>
