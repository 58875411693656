<template>
  <TableViewWithTitle title="발주 메모">
    <template v-slot:headLeft>
      <p class="desc_tip">
        <span class="ico_purchase ico_exclam" />해당 메모는 발주서에 노출되지 않으며, 보여집니다.
      </p>
    </template>
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col />
    </template>
    <template v-slot:body>
      <tr>
        <th>발주 메모</th>
        <td class="h96 td_text" v-html="memo" />
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "OrderMemo",
  components: {
    TableViewWithTitle,
  },
  props: {
    memo: String,
  },
};
</script>
