<template>
  <div class="area_estimate">
    <div class="tbl_top">
      <span class="txt_head">(단위 : {{ currency }}, VAT 별도)</span>
    </div>
    <div class="tbl_print tbl_list">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>품명</th>
            <th>제조사</th>
            <th>수량</th>
            <th>단가</th>
            <th>금액</th>
            <th>비고</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.goodsItem || "" }}</td>
            <td>{{ item.productor || "" }}</td>
            <td>{{ getCountWithQuantity(item.amount, item.quantity) }}</td>
            <td>{{ getCountWithCurrency(item.unitPrice, item.currency || currency) }}</td>
            <td>{{ getCountWithCurrency(item.price, item.currency || currency) }}</td>
            <td>{{ item.goodsNote || "" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getCountWithCurrency, getCountWithQuantity } from "@/utils/spUtils";

export default {
  name: "OrderPrintEstimate",
  props: {
    dataList: Array,
    currency: String,
  },
  methods: {
    getCountWithCurrency(count, value) {
      return getCountWithCurrency(count, value);
    },
    getCountWithQuantity(count, value) {
      return getCountWithQuantity(count, value);
    },
  },
};
</script>
