<template>
  <div class="group_total">
    <div class="box_total">
      <div class="total_head">
        <strong class="tit_total">소계</strong>
        <div class="count_total">
          <strong class="tit_count">수량</strong>
          <span class="txt_bar" />
          <span class="txt_count">{{ amount }}</span>
        </div>
      </div>
      <span class="txt_total" v-html="price" />
    </div>
    <div class="box_total">
      <div class="total_head">
        <strong class="tit_total">절사 금액</strong>
        <div class="count_total">
          <strong class="tit_count">수량</strong>
          <span class="txt_bar" />
          <span class="txt_count">{{ amount }}</span>
        </div>
      </div>
      <span class="txt_total" v-html="truncationPrice" />
    </div>
    <div class="box_total">
      <div class="total_head">
        <strong class="tit_total">합계 금액</strong>
        <div class="count_total">
          <strong class="tit_count">수량</strong>
          <span class="txt_bar" />
          <span class="txt_count">{{ amount }}</span>
        </div>
      </div>
      <span class="txt_total" v-html="sumPrice" />
    </div>
  </div>
</template>

<script>
import { addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "OrderPrintTotal",
  props: {
    model: Object,
    currency: String,
  },
  computed: {
    amount() {
      if (!this.model || !this.model.amount) return "";

      return addCommaForMoney(this.model.amount);
    },
    price() {
      if (!this.model) return "";

      const result =
        addCommaForMoney(this.model.price) + ' <span class="txt_util">' + this.currency + "</span>";
      return result;
    },
    sumPrice() {
      if (!this.model) return "";

      const result =
        addCommaForMoney(this.model.sumPrice) +
        ' <span class="txt_util">' +
        this.currency +
        "</span>";
      return result;
    },
    truncationPrice() {
      if (!this.model) return "";

      const result =
        addCommaForMoney(this.model.truncationPrice) +
        ' <span class="txt_util">' +
        this.currency +
        "</span>";
      return result;
    },
  },
};
</script>
