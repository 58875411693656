<template>
  <tr v-if="!isAgreeYn">
    <!-- 변경요청일 -->
    <td class="align_center">
      {{ item.fromGoodsDeadline }}
      <span class="material-icons icons-deadline"> south </span>
      {{ item.toGoodsDeadline }}
    </td>
    <!-- 변경사유 -->
    <td class="td_preline align_top">{{ item.requestReason }}</td>
    <!-- 승인여부 -->
    <td class="align_center">
      {{ computedAgreeText }}
    </td>
    <!-- 승인일시 -->
    <td class="align_center">{{ item.agreeDate | dateTimeStringFormat }}</td>
    <!-- 승인자 -->
    <td class="align_center">
      {{ item.managerLoginId ? `${item.managerName}(${item.managerLoginId})` : "-" }}
    </td>
    <!-- 승인거절 사유 -->
    <td class="td_preline align_top">{{ item.comment | nullToDash }}</td>
  </tr>
  <tr v-else>
    <!-- 변경요청일 -->
    <td class="align_center">
      {{ item.fromGoodsDeadline }}
      <span class="material-icons icons-deadline"> south </span>
      {{ item.toGoodsDeadline }}
    </td>
    <!-- 변경사유 -->
    <td class="td_preline align_top">{{ item.requestReason }}</td>
    <!-- 승인여부 -->
    <td class="align_center">
      <button class="btn_primary btn_small" @click="onClickAgree">승인</button>
      <button class="btn_fifthly btn_small" @click="onClickDisagree">거절</button>
    </td>
    <!-- 승인일시 -->
    <td class="align_center">
      <DatePicker :isDisabled="true" :dateString.sync="agreeDate" />
    </td>
    <!-- 승인자 -->
    <td class="align_center">
      {{ computedManager }}
    </td>
    <!-- 승인거절 사유 -->
    <td class="td_preline">
      <Textarea
        :value.sync="comment"
        :maxLength="300"
        :isCount="true"
        placeholder="사유를 입력하세요."
      />
    </td>
  </tr>
</template>

<script>
import Textarea from "@/components/common/textarea/Textarea";
import DatePicker from "@/components/common/calendar/DatePicker";
import { escapeRegExp } from "@/utils/spUtils";

import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "GoodsDeadlineHistoryLine",
  components: {
    Textarea,
    DatePicker,
  },

  props: {
    item: Object,
    orderCid: String,
  },
  data() {
    return {
      reqId: this.item.reqId,
      agreeYn: false,
      comment: "",
      agreeDate: this.moment(new Date()).format("YYYY-MM-DD"),
      manager: "",
    };
  },
  computed: {
    isAgreeYn() {
      return this.item.agreeYn === "";
    },
    computedAgreeText() {
      let ret = "대기";
      if (this.item.agreeYn === "") {
        ret = "대기";
      } else if (this.item.agreeYn) {
        ret = "승인";
      } else {
        ret = "거절";
      }
      return ret;
    },
    computedManager() {
      const userData = LocalStorageManager.shared.getUserData();
      const ret = `${userData.name}(${userData.username})`;
      return ret;
    },
  },
  created() {},
  methods: {
    onClickAgree() {
      if (this.getAlertMessage()) {
        return;
      }
      this.agreeYn = true;
      const params = {
        text: `${this.item.fromGoodsDeadline} > <strong>${this.item.toGoodsDeadline}</strong><br />납기일 변경을 승인하시겠습니까?`,
        onClickY: () => {
          this.submit();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickDisagree() {
      if (this.getAlertMessage()) {
        return;
      }
      this.agreeYn = false;
      const params = {
        text: `납기일 변경을 거절하시겠습니까?`,
        onClickY: () => {
          this.submit();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async submit() {
      const path = `${this.$apiPath.ORDER}/deadline`;
      const { orderCid, reqId, agreeYn, comment } = this;

      const obj = {
        orderCid,
        reqId,
        agreeYn,
        comment,
      };

      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: text,
        });
        return;
      }

      if (data) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: agreeYn ? "승인처리 되었습니다." : "거절처리 되었습니다.",
        });
        this.$emit("getOrderData");
      }
    },
    getAlertMessage() {
      if (!this.comment) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: "승인/거절 사유를 입력하세요." || "error",
        });
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.icons-deadline {
  display: block;
  font-size: 18px;
}
.btn_small {
  display: block;
  width: 80px;
  margin: 10px auto 0;
  &:first-child {
    margin-top: 0;
  }
}
</style>
