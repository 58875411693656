<template>
  <OrderPrintPage v-bind="$props">
    <!-- 2p 발주서 일반조건 -->
    <div class="cont_print">
      <OrderPrintHeader :orderNumber="orderNumber" />
      <h4 class="tit_print">발주일반계약</h4>
      <OrderPrintTerm01 :adminCompanyName="adminCompanyName" />
    </div>
  </OrderPrintPage>
</template>

<script>
import OrderPrintPage from "./OrderPrintPage";
import OrderPrintHeader from "./OrderPrintHeader";
import OrderPrintTerm01 from "./OrderPrintTerm01";

export default {
  name: "OrderPrintLevel2",
  components: {
    OrderPrintPage,
    OrderPrintHeader,
    OrderPrintTerm01,
  },
  props: {
    orderNumber: String,
    adminCompanyName: String,
  },
};
</script>
