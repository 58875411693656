<template>
  <div :class="['group_form group_count', { on: isDirectMode }]">
    <InputAutoMoney
      v-if="isAutoMoneyType"
      class="tf_count"
      :maxLength="6"
      :value.sync="countSync"
      :isDisabled="isDisabledInputCount"
    />
    <InputNumber
      v-else
      class="tf_count"
      :maxLength="6"
      :value.sync="countSync"
      :isDisabled="isDisabledInputCount"
    />
    <!-- <Input
      v-if="isDirectMode"
      class="tf_util"
      :maxLength="3"
      :value.sync="valueSync"
      :isDisabled="isDisabledSelectBox"
    />
    <SelectBox
      :dataList="quantityList"
      :value.sync="selectedValue"
      :isDisabled="isDisabledSelectBox"
      @updateItem="onChangeSelect" -->
    />
    <Input
      v-if="isDirectMode"
      class="tf_util"
      :maxLength="6"
      :value.sync="unitValueSync"
      :isDisabled="isDisabledSelectBox"
    />
    <SelectBox
      :dataList="quantityList"
      :value.sync="unitTypeSync"
      :isDisabled="isDisabledSelectBox"
      @updateItem="onChangeSelect"
    />
  </div>
</template>

<script>
import SelectBox from "./SelectBox";
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import { mapState } from "vuex";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";

export default {
  //직업입력 tf + 수량 -> 유동너비적용
  name: "SelectBoxQuantityIncludeData",
  components: {
    SelectBox,
    Input,
    InputNumber,
    InputAutoMoney,
  },
  props: {
    unitValue: String,
    unitType: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
    isDisabledInputCount: {
      type: Boolean,
      default: false,
    },
    count: [Number, String],
    isAutoMoneyType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: "EA",
    };
  },
  mounted() {
    this.$store.dispatch(COMMON_CODE_ACTION.GET_QUANTITY);

    this.setValueByProps();
  },
  computed: {
    ...mapState({
      quantityList: (state) => state.commonCode.quantity,
    }),
    isDirectMode() {
      return this.unitType === "T";
    },
    countSync: {
      get() {
        return this.count;
      },
      set(value) {
        this.$emit("update:count", value);
      },
    },
    unitValueSync: {
      get() {
        return this.unitValue;
      },
      set(value) {
        this.$emit("update:unitValue", value);
      },
    },
    unitTypeSync: {
      get() {
        return this.unitType;
      },
      set(value) {
        this.$emit("update:unitType", value);
      },
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.setValueByProps();
    },
  },
  methods: {
    onChangeSelect(value) {
      if (this.isDirectMode) {
        this.$emit("update:unitValue", value.name);
      } else {
        this.$emit("update:unitValue", "");
      }
    },
    setValueByProps() {
      var toSelectedValue;
      switch (this.value) {
        case "EA":
        case "Copy":
        case "식":
          toSelectedValue = this.value;
          break;
        default:
          toSelectedValue = "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
  },
};
</script>
<style scoped>
.group_count {
  position: relative;
  padding: 0 8px 0 70%;
}
.tf_count {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
}

.group_count.on {
  padding: 0 16px 0 60%;
}
.group_count.on .tf_count,
.group_count.on .tf_util {
  width: 30%;
}
.group_count.on .tf_util {
  position: absolute;
  top: 0;
  left: 30%;
}
.group_count.on .tf_comm + .select_comm {
  margin-left: 16px;
}
</style>
