<template>
  <div class="area_term">
    <strong class="tit_term">제 1 조 (목적)</strong>
    <p class="desc_term">
      본 발주는 거래를 진행하는 업체(이하 “파트너사”)가 {{ adminCompanyName }}(이하 “회사”)에게
      [물품구매발주서] 소정의 하드웨어, 소프트웨어, 관련 장비 기타 물품(이하 “물품”)을 공급하고,
      “회사”가 “파트너사”에게 그 대가를 지급하는 발주를 체결함에 있어 당사자간 권리, 의무와 관련
      제반 책임 등 기본적인 사항을 정함에 있다.
    </p>
    <strong class="tit_term">제 2 조 (발주의 해석 및 효력)</strong>
    <ul class="list_term">
      <li>
        ① 본 발주를 구성하는 내용은 물품구매발주서, 발주일반계약, 물품구매내역서 등 “회사”가
        제공하거나 “회사”와 “파트너사”가 합의한 일체의 서류(이하 “발주서류”)와 “회사”의 요청에
        의하여 “파트너사”가 확인, 수락한 사항을 말한다. “파트너사”는 이 모든 사항에 대해 완전히
        인지하고 수락하였으며, 전체 또는 일부를 이해하지 못하였다는 이유로 책임을 면하지 못한다.
      </li>
      <li>
        ② “발주서류” 간 저촉이 있는 경우의 적용 우선순위는 1. “회사”와 “파트너사”가 합의한 서류, 2.
        물품구매발주서, 3. 발주일반계약 순으로 한다.
      </li>
    </ul>
    <strong class="tit_term">제 3 조 (물품의 납품)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”는 물품구매발주서에 기재된 날(이하 “이행기”)까지 “회사”가 지정하는 장소에
        “물품”을 납품, 설치한다.
      </li>
      <li>② “파트너사”는 “회사”의 사전 동의를 얻어 각 “이행기” 이전에 “물품”을 공급할 수 있다.</li>
      <li>
        ③ “파트너사”는 부득이한 사정으로 “이행기” 내에 공급을 완료할 수 없을 경우에는 사전에 그
        사실을 “회사”에 통지하고 동의를 구한 후 상호 협의 하에 처리하는 것으로 한다. 단, 이 경우에도
        “파트너사”는 “회사”의 명시적인 서면 동의가 없는 한 이행지체에 따른 지체상금을 부담하여야
        한다.
      </li>
    </ul>
    <strong class="tit_term">제 4 조 (물품대금)</strong>
    <ul class="list_term">
      <li>
        ① “회사”는 “파트너사”가 “물품”을 공급하는 대가로 물품구매발주서에 기재된 금액(이하
        “발주금액”)을 “파트너사”에 지급하기로 하며, 대가의 세부내역은 [물품구매발주서]와 같다.
      </li>
      <li>
        ② 다만, 발주 체결 당시 예측할 수 없었던 현저하고 명백한 사정의 변경, “물품”의 대가 결정에
        있어 중대한 착오 또는 하자가 있는 경우로서 양사간 기존 합의에 따라 발주를 이행하는 것이
        적절하지 아니하다고 인정되는 경우, 해당 당사자는 합리적인 범위 내에서의 “발주금액”의 증감을
        요구할 수 있고, 이 경우 그 금액을 당사자간 서면 합의로 조정할 수 있다.
      </li>
    </ul>
    <strong class="tit_term">제 5 조 (대금의 청구 및 지급)</strong>
    <ul class="list_term">
      <li>
        ① “물품”의 대금은 발주 체결 또는 납품 시에 청구하며 별도 정함이 없는 한 물품구매발주서
        지급방법란의 기재에 따라 지급한다.
      </li>
      <li>
        ② “회사”와 “파트너사”는 본 발주의 효율적인 이행을 위하여 필요한 경우에 선급금 지급조항을 둘
        수 있으며, 이 경우 “회사”는 별도 합의한 바에 따라 선급금을 “파트너사”에게 지급하기로 한다.
        다만, 이 경우 “파트너사”는 선급금 수령 전까지 선급금 전액에 대하여 “회사”를 피보험자로 하는
        선급금이행보증보험증권을 “회사”에게 제출하여야 한다. 다만, “회사”와의 별도 합의에 따라 그
        제출을 면제받은 경우에는 “파트너사”는 “회사”에게 수령할 선급금 전액에 대한 지급각서를
        제출하여야 한다.
      </li>
    </ul>
    <strong class="tit_term">제 6 조 (발주이행보증)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”는 본 발주의 성실한 이행을 보증하기 위하여 물품구매발주서에 기재된 바에 따라
        발주이행보증보험증권을 “회사”에게 제출한다. 다만, 양 당사자간의 합의에 따라
        발주이행보증보험증권의 제출을 생략할 수 있다.
      </li>
      <li>
        ② 전항 단서에 의하여 발주이행보증보험증권 등의 제출이 면제된 경우, “회사”는 “파트너사”가 본
        발주상의 의무를 이행하지 못하거나 이행하지 못할 것으로 예상되는 등 발주이행보증이 필요하다고
        판단될 경우에는 발주금액의 100 분의 10 에 해당하는 금액을 발주이행보증금으로 납부할 것을
        요청할 수 있으며, “파트너사”는 조건 없이 이에 응하여야 한다. 단, 발주이행보증금액에 대한
        별도의 합의가 있는 경우에는 그에 따른다.
      </li>
    </ul>
    <strong class="tit_term">제 7 조 (지체상금)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”는 “파트너사”의 고의 또는 과실에 기하여 본 발주에서 정한 기한 내에 “회사”에게
        해당 “물품”을 공급할 수 없는 경우에는 지체일수 1 일에 대하여 발주금액의 1000 분의 1.5 에
        해당하는 금액(이하 “지체상금”)을 현금으로 납부하여야 하며, “회사”는 위 지체일이 100 일을
        초과하거나 지체상태로 보아 “파트너사”의 발주이행이 불가능하다고 판단되는 경우에는 100 일
        전이라도 발주를 해제 또는 해지할 수 있으며 본 발주의 계속 이행이 필요하다고 판단될 시 발주를
        해제 또는 해지하지 않을 수 있다.
      </li>
      <li>
        ② “회사”는 “파트너사”가 납부할 “지체상금”을 “파트너사”에게 지급할 “발주금액”에서 공제할 수
        있다.
      </li>
      <li>
        ③ 다만, 본 발주의 이행지체가 천재지변, 정부의 규제, 폭동 등 불가항력적인 사유 또는 “회사”의
        귀책사유로 인한 경우에는 “파트너사”는 위 제 1 항의 이행지체책임을 면한다. 그러나 이 경우에도
        “파트너사”는 발주를 신속히 이행하기 위하여 최선을 다하여야 하며, 해당 사유의 발생 및
        진행상황을 수시로 “회사”에게 통보하여야 한다.
      </li>
    </ul>
    <strong class="tit_term">제 8 조 (물품의 인도 및 검수)</strong>
    <ul class="list_term">
      <li>
        ① “회사”는 “파트너사”가 “물품”을 인도 또는 설치할 수 있도록 “회사”가 지정한 설치장소에
        부대설비 등의 환경을 제공하여야 하며, 이에 필요한 비용은 “회사”가 부담하기로 한다. 단, 본
        발주 [별첨1 물품구매발주서]에 산정하지 않은 금액으로서 “회사”의 요청에 의하여 부대설비 등의
        환경을 제공한 경우, “파트너사”는 부대설비 공사를 시행한 후 “회사”에게 그 비용을 별도 청구할
        수 있다.
      </li>
      <li>
        ② “물품”의 도입, 납품에 필요한 수입통관처리(수입품의 경우), “물품”의 포장 및 운송, 설치
        장소에의 반입, 설치 등은 본 “발주금액”에 포함된 것으로 보며, “파트너사”의 책임과 비용으로
        처리하기로 한다.
      </li>
      <li>
        ③ “파트너사”는 본 물품구매발주서의 "이행기”까지 “물품”을 납품하여야 하며, “물품”의 납품을
        완료하였을 경우 그 사실 및 검수요청을 서면으로 “회사”에게 통지 후 필요한 검사 또는 검수를
        받아야 한다.
      </li>
      <li>
        ④ “회사”는 전항의 통지를 받은 때에는 인수한 “물품”을 즉시 검수하여 하자 유무를 확인한 후
        하자가 없을 경우에는 “파트너사”의 검수요청 통지 수령일로부터 10 일 이내에 검수 결과를
        “파트너사”에게 서면 통지하여야 하며, 본 기간 내에 서면 통지가 없는 경우에는 검수를 완전히
        통과한 것으로 본다. 다만, 위 기간 내에 검수를 마칠 수 없는 정당한 사유가 “회사”에게 있는
        경우에는 그러하지 아니하다.
      </li>
      <li>
        ⑤ “회사”는 전항의 검수 과정에서 “파트너사”의 발주이행내용의 전부 또는 일부가 발주에
        위반되거나 하자를 발견한 때에는 그 위반 사항의 시정 내지 하자의 치유를 요구할 수 있으며,
        “회사”는 “파트너사”로부터 발주의 전부이행 또는 하자의 치유사실을 통지 받은 날로부터 10일
        이내에 검수 결과를 “파트너사”에게 서면 통지하기로 한다.
      </li>
      <li>
        ⑥ 전항에 의하여
        {{
          adminCompanyName == "카카오아이엑스 주식회사" ? "본 발주에서 정한 기한이" : "발주기간이"
        }}
        연장된 경우, “회사”는 “파트너사”에 연장기간만큼 제 7 조에 의한 “지체상금”을 부과한다.
      </li>
      <li>
        ⑦ 만약, “파트너사”가 본 조 제 4 항 및 제 5 항에 따른 “회사”의 조치에 이의가 있을 경우에는
        “회사”에게 재검사를 요청 할 수 있으며, 이 경우, “회사”는 “파트너사”의 비용 부담으로 지체
        없이 재검사를 하여야 한다.
      </li>
      <li>
        ⑧ “파트너사”가 공급한 “물품”이 “회사”의 검수 합격에도 불구하고 숨은 하자가 발생한 경우,
        “파트너사”는 정상적인 제품으로 신속히 교체하거나, “파트너사”의 비용 부담으로 정상적인 가동이
        가능할 수 있도록 수리를 하여야 한다.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OrderPrintTerm01",
  props: {
    adminCompanyName: String,
  },
};
</script>
