<template>
  <div class="area_term">
    <strong class="tit_term">제 9 조 (지원 인력의 파견)</strong>
    <p class="desc_term">
      본 발주의 이행과 관련하여 “회사”의 설치장소 또는 “회사”의 업무수행장소에 지원인력의 파견이
      필요한 경우, 당사자 간의 협의에 의하여 “파트너사”의 지원인력의 파견을 요청할 수 있다. 이 경우,
      “파트너사”의 파견인력에 대한 비용은 양사간 비용 부담의 별도 합의가 없는 한 “발주금액”에
      포함되는 것으로 한다.
    </p>
    <strong class="tit_term">제 10 조 (물품에 대한 하자 보증)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”는 “회사”의 검수완료일로부터 12개월간 또는 양사가 별도 합의한 기간 동안 납품
        “물품”에 대한 하자가 없음을 보증 하고, “물품” 하자에 대하여 무상으로 정비, 보수할 의무가
        있음을 확인하며, 해당 기간에 대하여 “발주금액”의 100분의 5에 해당하는 금액에 대한
        하자보증보험증권을 제출하여야 한다. 다만, “회사”가 하자보증보험증권의 제출을 면제한 경우는
        하자보증금지급각서를 제출하여야 한다.
      </li>
      <li>
        ② “파트너사”가 제공한 “물품”이 규격상이, 품질불량, 파손 등 “파트너사”의 귀책사유에 의한
        하자로 인하여 정상적으로 작동하지 아니하는 경우, “파트너사”의 부담 하에 하자가 없는
        동종제품으로 교체하거나, 별도로 보수 일정을 협의하지 아니하는 한, “회사”의 요청일로부터 즉시
        또는 최소 (24)시간 이내에 하자를 치유하여야 한다.
      </li>
      <li>
        ③ 단, 전항의 하자 발생의 원인이 “회사”의 고의 또는 취급 부주의 등 “회사”의 과실로 인한 경우
        및 “회사”의 통상적인 사용으로 인한 “물품”의 마모, 파손으로 인한 하자보수의 경우에는 해당
        비용은 “회사”의 부담으로 한다.
      </li>
      <li>
        ④ “파트너사”는 무상하자보증기간이 종료한 후 특별한 사정이 없는 한 “회사”의 유지보수 발주
        체결 요청이 있을 경우 반드시 이에 응하여야 하며, 위 제 1 항의 무상하자보증기간 이후의
        “물품”에 대한 유지보수는 당사자 간에 별도로 서면 합의에 의해 정하는 것으로 한다.
      </li>
      <li>
        ⑤ 전항과 관련하여 “파트너사”의 사정으로 인하여 “회사”의 유지보수 발주 체결 요청에 적시에
        응하지 못할 경우, 늦어도 무상하자보증기간 만료 2개월 전에 “파트너사”를 대신할 다른 유지보수
        업체를 선정하여 “회사”에게 통지하여야 한다.
      </li>
    </ul>
    <strong class="tit_term">제 11 조 (위험의 부담)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”가 “물품”을 “회사”의 지정장소에 인도, 검수 완료하기 전에 발생한 “물품”의 멸실
        또는 훼손에 대하여는 “회사”의 귀책사유에 기한 경우임이 명백함을 입증한 경우를 제외하고는
        “파트너사”가 해당 “물품”의 멸실 또는 훼손에 대한 위험과 책임을 부담하기로 한다.
      </li>
      <li>
        ② “회사”의 지정장소에 “물품”이 인도되어 검수 완료 후 해당 “물품”이 멸실 또는 훼손된 경우에는
        “파트너사”의 귀책사유로 인한 경우를 제외하고는 “회사”가 해당 멸실 또는 훼손에 대한 위험과
        책임을 부담하기로 한다.
      </li>
    </ul>
    <strong class="tit_term">제 12 조 (보고 및 교육)</strong>
    <ul class="list_term">
      <li>
        ① “회사”는 필요한 경우, “물품” 공급 및 설치 등의 진척사항에 대하여 “파트너사”에게 구두 또는
        서면 보고를 요구할 수 있으며, “파트너사”는 특별한 사정이 없는 한 즉시 이에 응하여야 한다.
      </li>
      <li>
        ② “회사”는 “파트너사”에게 “물품”의 설치, 운영 및 유지에 필요한 교육 또는 기술의 자문을
        요청할 수 있으며, “파트너사”는 “회사”와의 별도 서면 합의가 없는 한 무상으로 이를 제공하여야
        한다.
      </li>
    </ul>
    <strong class="tit_term">제 13 조 (발주의 해제, 해지)</strong>
    <ul class="list_term">
      <li>
        ① 당사자 일방이 본 발주 상의 중대한 의무를 위반하여 상대방으로부터 그 위반행위에 대한 서면
        시정요구를 받고도 3일 이내에 시정하지 않는 경우, 상대방은 본 발주를 해제 또는 해지할 수
        있다.
      </li>
      <li>
        ② 전항에도 불구하고, 다음 각 1호에 해당하는 경우 그 일방은 상대방에 대하여 기한의 이익을
        상실하며, 일방은 본 발주를 즉시 해약할 수 있다.
        <ul class="list_term2">
          <li>
            1. 당사자 일방이 부도, 파산, 워크아웃, 회생절차 개시신청을 하거나 이에 해당할 상당한
            우려가 있어 본 발주를 정상적으로 이행할 수 없게 된 경우
          </li>
          <li>
            2. 당사자 일방이 압류, 가처분, 경매 등의 강제집행을 당하여 본 발주를 정상적으로 이행할
            수 없게 된 경 우
          </li>
          <li>
            3. 법률, 법원의 판결이나 명령, 정부의 명령(행정지도 포함) 등으로 인하여 본 발주의
            정상적인 이행이 불가능한 경우
          </li>
        </ul>
      </li>
      <li>
        ③ “파트너사”의 책임 있는 사유로 본 발주가 해제 또는 해지된 경우, “회사”가 이미 검수를
        완료하여 “물품”을 전부 또는 일부 인수한 경우에는 해당 인수한 “물품”의 대가에 상당하는 대금을
        “파트너사”에게 지급하기로 하되, 기 인수한 “물품”만으로는 발주의 목적의 달성이 불가능하거나,
        향후 발주 목적 달성을 위하여 “회사”가 추가비용을 지출하여야 하는 경우에는 해당 대가를
        감액하고 “파트너사”에게 지급할 수 있다.
      </li>
    </ul>
    <strong class="tit_term">제 14 조 (손해배상)</strong>
    <ul class="list_term">
      <li>
        ① 당사자 중 일방이 본 발주를 이행함에 있어 고의 또는 과실로 상대방에게 손해를 입힌 경우,
        타방 당사자가 입은 손해를 배상하여야 한다. 다만, 천재지변 등 기타 불가항력적인 요인으로 인한
        손해 발생의 경우에는 그러하지 아니한다.
      </li>
      <li>
        ② “파트너사”의 고의, 과실에 기하여 “회사”에게 손해가 발생하여 본 발주 제6조 및 제10조 소정의
        각 보증금이 “회사”가 “파트너사”에게 청구할 손해배상금의 전보에 부족할 경우, 그 부족액에
        대하여는 “회사”가 “파트너사”에게 별도로 청구할 수 있다.
      </li>
    </ul>
    <strong class="tit_term">제 15 조 (권리의무의 양도 금지)</strong>
    <ul class="list_term">
      <li>
        ① “회사”와 “파트너사”는 본 발주 상의 권리 의무를 법률이나 법원의 명령 또는 일방 당사자의
        사전 서면 승인 없이 제3자에게 양도 할 수 없다.
      </li>
      <li>
        ② “파트너사”는 “회사”의 사전 서면 승인 없이 물품구매발주서에 명기된 “물품” 제공자를
        변경하거나 “물품” 일부의 납품을 제3자에 하도급 또는 위임할 수 없으며, “회사”의 사전 승인에
        따라 “물품” 제공자를 변경하거나 하도급한 경우라도 “파트너사”는 해당 당사자와 연대하여 본
        발주에 의하여 발생한 책임을 부담하기로 한다.
      </li>
    </ul>
    <strong class="tit_term">제 16 조 (지적재산권 등)</strong>
    <ul class="list_term">
      <li>
        ① “파트너사”는 본 “물품”의 납품과 관련하여 “회사” 또는 “파트너사”가 타인의 지적재산권 등을
        침해하는 것이 아님을 보증하며, 이에 대하여 전적인 책임을 지기로 한다.
      </li>
      <li>
        ② “파트너사”가 납품한 “물품”에 관하여 “회사” 또는 “파트너사”와 제3자 사이에 지적재산권 등에
        관한 분쟁이 발생한 경우 “파트너사”는 자신의 비용으로 이를 방어하여야 하며, 만일 위 분쟁으로
        인하여 “회사”가 손해를 입은 경우에는, “파트너사”는 변호사 보수를 포함한 제반 경비 및 신용
        상의 손해를 포함하여 “회사”가 입은 모든 손해를 배상하여야 한다.
      </li>
    </ul>
    <strong class="tit_term">제 17 조 (비밀유지의무)</strong>
    <ul class="list_term">
      <li>
        ① “회사”는 본 발주 이행과 관련하여 “파트너사”가 제출하는 각종 보고서, 정보, 기타 자료 및
        이에 의하여 얻은 기술지식의 전부 또는 일부를 “회사”의 이익을 위하여 복사, 이용 또는 공개하기
        위해서는 “파트너사”의 사전 승인을 얻어야 한다.
      </li>
      <li>
        ② “파트너사”는 본 발주를 통하여 얻은 모든 정보 또는 기밀사항을 발주 이행의 전후를 막론하고
        비밀로서 취급하여야 하고, 외부에 누설하거나 발주 이행 이외의 목적으로 사용할 수 없으며,
        “회사”의 사전 서면 승인이 있지 아니하는 한, 발주의 이행과 관련하여 “회사”로부터 지득한
        자료에 대하여 지적재산권을 취득하여서는 아니 된다.
      </li>
      <li>
        ③ “파트너사”는 본 발주의 종료 또는 해제, 해지시 “회사”가 제공한 각종 기술정보와 자료물 등을
        “회사”에게 즉시 반환하여야 한다.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OrderPrintTerm02",
};
</script>
