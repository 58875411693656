<template>
  <OrderPrintPage v-bind="$props">
    <!-- 4p 발주서 일반조건 -->
    <div class="cont_print">
      <OrderPrintHeader :orderNumber="orderNumber" />
      <!-- <h2 class="tit_print">발주일반계약</h2> -->
      <OrderPrintTerm03 />
      <OrderPrintOath :model="orderPrintOath" />
      <div v-if="isShowAgreeButton" class="area_btn">
        <button type="button" class="btn_agree" @click="onClickAgree">동의</button>
      </div>
      <AttachedFileView
        v-if="attachFileList && attachFileList.length > 0"
        :dataList="attachFileList"
      />
    </div>
  </OrderPrintPage>
</template>

<script>
import OrderPrintPage from "./OrderPrintPage";
import OrderPrintHeader from "./OrderPrintHeader";
import OrderPrintTerm03 from "./OrderPrintTerm03";
import OrderPrintOath from "./OrderPrintOath";

import FileView from "@/components/common/file/FileView";

import AttachedFileView from "@/components/common/file/AttachedFileView";

export default {
  name: "OrderPrintLevel4",
  components: {
    OrderPrintPage,
    OrderPrintHeader,
    OrderPrintTerm03,
    OrderPrintOath,
    FileView,
    AttachedFileView,
  },
  props: {
    currentPage: Number,
    totalPageCount: Number,
    orderNumber: String,
    isShow: Boolean,
    isShowAgreeButton: Boolean,
    orderPrintOath: Object,
    attachFileList: Array,
  },
  methods: {
    onClickAgree() {
      // this.$emit( 'update:isAgree', true );
      this.$emit("onClickAgree", true);
    },
  },
};
</script>
