<template>
  <OrderPrintPage v-bind="$props">
    <!-- 1p 발주서 -->
    <div class="cont_print">
      <OrderPrintHeader :orderNumber="orderNumber" />
      <h4 class="tit_print" v-html="title" />
      <OrderPrintInfo :model="orderPrintInfoModel" :currency="currency" />
      <OrderPrintEstimate :dataList="supplyInfoModelList" :currency="currency" />
      <OrderPrintTotal :model="orderPrintTotalModel" :currency="currency" />
      <OrderPrintTip :model="paymentInfoModel" />
    </div>
  </OrderPrintPage>
</template>

<script>
import OrderPrintPage from "./OrderPrintPage";
import OrderPrintHeader from "./OrderPrintHeader";
import OrderPrintInfo from "./OrderPrintInfo";
import OrderPrintEstimate from "./OrderPrintEstimate";
import OrderPrintTotal from "./OrderPrintTotal";
import OrderPrintTip from "./OrderPrintTip";

export default {
  name: "OrderPrintLevel1",
  components: {
    OrderPrintPage,
    OrderPrintHeader,
    OrderPrintInfo,
    OrderPrintEstimate,
    OrderPrintTotal,
    OrderPrintTip,
  },
  props: {
    currentPage: Number,
    totalPageCount: Number,
    orderNumber: String,
    type: Number,
    isShow: Boolean,

    orderPrintInfoModel: Object,
    orderPrintTotalModel: Object,
    paymentInfoModel: Object,
    supplyInfoModelList: Array,

    title: String,
    currency: String,
  },
};
</script>
