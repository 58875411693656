<template>
  <PrintPage>
    <template v-slot:content>
      <slot />
    </template>
  </PrintPage>
</template>

<script>
import PrintPage from "./PrintPage.vue";

export default {
  name: "OrderPrintPage",
  components: {
    PrintPage,
  },
  // ,
  // props: {
  //   currentPage: Number,
  //   totalPageCount: Number,
  //   orderNumber: String,
  //   isShow: Boolean
  // }
};
</script>
