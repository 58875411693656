<template>
  <TableViewWithTitle title="입찰공고 정보">
    <template v-if="showPostCid" v-slot:body>
      <tr>
        <th>입찰 공고번호</th>
        <td colspan="3">
          {{ (model && model.postCid) || "" }}
        </td>
      </tr>
      <tr>
        <th>입찰 공고명</th>
        <td class="td_text">
          <router-link :to="toPath" class="link_subject" target="_blank" rel="opener">
            {{ (model && model.title) || "" }}
          </router-link>
        </td>
        <th>입찰 시작일</th>
        <td>{{ startDate }}</td>
      </tr>
      <tr>
        <th>투찰금액</th>
        <td>
          {{ price && model && model.currency ? getCountWithCurrency(price, model.currency) : "-" }}
        </td>
        <th>입찰 마감일</th>
        <td>{{ endDate }}</td>
      </tr>
      <tr>
        <th>분류</th>
        <td colspan="3">
          {{ category }}
        </td>
      </tr>
    </template>
    <template v-else v-slot:body>
      <tr>
        <th>입찰 공고명</th>
        <td class="td_text">
          {{ (model && model.title) || "" }}
        </td>
        <th>입찰 시작일</th>
        <td>{{ startDate }}</td>
      </tr>
      <tr>
        <th>투찰금액</th>
        <td>{{ (postTender && postTender.price) || "" }}</td>
        <th>입찰 마감일</th>
        <td>{{ endDate }}</td>
      </tr>
      <tr>
        <th>분류</th>
        <td colspan="3">
          {{ category }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import { getDateString } from "@/utils/dateUtils";
import { getCountWithCurrency, getCategoryDepthText } from "@/utils/spUtils";

export default {
  name: "AnnounceInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    model: Object,
    showPostCid: Boolean,
    postTender: Object,
  },
  computed: {
    toPath() {
      if (!this.model || !this.model.postCid) return "";

      const path = `${this.$routerPath.ANNOUNCE_VIEW}/${this.model.postCid}`;
      return path;
    },
    startDate() {
      if (!this.model || !this.model.startDate) return "";
      return getDateString(this.model.startDate);
    },
    endDate() {
      if (!this.model || !this.model.endDate) return "";
      return getDateString(this.model.endDate);
    },
    price() {
      if (!this.postTender || !this.postTender.price) return "";
      return this.postTender.price;
    },
    category() {
      if (!this.model || !this.model.category) return "";
      return getCategoryDepthText(this.model.category);
    },
  },
  methods: {
    getCountWithCurrency(count, value) {
      return getCountWithCurrency(count, value);
    },
  },
};
</script>
