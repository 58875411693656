<template>
  <OrderPrintPage v-bind="$props">
    <!-- 3p 발주서 일반조건 -->
    <div class="cont_print">
      <OrderPrintHeader :orderNumber="orderNumber" />
      <!-- <h2 class="tit_print">발주일반계약</h2> -->
      <OrderPrintTerm02 />
    </div>
  </OrderPrintPage>
</template>

<script>
import OrderPrintPage from "./OrderPrintPage";
import OrderPrintHeader from "./OrderPrintHeader";
import OrderPrintTerm02 from "./OrderPrintTerm02";
export default {
  name: "OrderPrintLevel3",
  components: {
    OrderPrintPage,
    OrderPrintHeader,
    OrderPrintTerm02,
  },
  props: {
    orderNumber: String,
  },
};
</script>
