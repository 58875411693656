<!--ALL               "전체",         "0",    
    TEMP              "발주서 작성중",  "T"
    COMPLETE          "발행완료",      "C"
    AGREE             "발주서 서명완료", "G  DB저장은 하지 않음. - Display용
    CANCEL            "발주취소",      "E"
    DELETE            "삭제",         "D"
    중도해지 'M'
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">
          발주 관리
          <span v-if="orderCid" class="txt_view">{{ orderCid }}</span>
        </h2>
      </div>
      <div class="body_section">
        <OrderInfo
          :orderCid="orderCid"
          :itemData.sync="itemData"
          :templateName="templateName"
          @onClickConnectPurchaseWfPost="onClickConnectPurchaseWfPost"
          @onClickConnectRequestPost="onClickConnectRequestPost"
          @onClickConnectContract="onClickConnectContract"
        />

        <ManagerInfo
          v-if="postBoard"
          :orderCid="orderCid"
          :purchasePersonList="postBoard.purchasePersonList"
          :status="itemData.status"
          @onClickPopLdap="onClickPopLdap"
        />
        <SupplyPartnerInfo :model="itemData.postTender" />
        <SupplyPartnerManagerInfo
          :tenderPersonList="itemData.postTender ? itemData.postTender.tenderPersonList : []"
        />

        <SupplyInfo
          v-if="itemData.orderGoodsAddInfo"
          :itemData="itemData"
          :orderGoodsList.sync="itemData.orderGoodsList"
          :orderInspectedGoodsList="itemData.orderInspectedGoodsList || []"
          :totalInspectAmount="itemData.totalInspectAmount || ''"
          :orderGoodsAddInfo.sync="itemData.orderGoodsAddInfo"
          :attachFile.sync="attachFile"
        />

        <PaymentMethod
          v-if="itemData.orderPayment"
          :orderPayment.sync="itemData.orderPayment"
          :attachFile.sync="attachFile"
        />

        <AddInfo
          v-if="itemData.orderGoodsAddInfo"
          :itemData.sync="itemData"
          :attachFile.sync="attachFile"
        />

        <!-- 납기일 변경 히스토리 -->
        <!-- <GoodsDeadlineHistory
          v-if="itemData.goodsDeadlineHistory && itemData.goodsDeadlineHistory.length > 0"
          :orderCid="orderCid"
          :dataList="itemData.goodsDeadlineHistory"
          @getOrderData="getData()"
        /> -->

        <template v-if="status === 'M' && itemData.changeReason">
          <TableViewWithTitle title="중도종결 사유">
            <template v-slot:body>
              <tr>
                <th>중도종결 사유</th>
                <td colspan="3" class="list_total td_preline">
                  {{ itemData.changeReason }}
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>

        <!-- <PaymentInfo :model="itemData" /> -->

        <!-- <OrderMemo :memo="itemData.memo" /> -->

        <!-- <PurchaseRequestConnectView
          v-if="
            isShowPurchaseRequest &&
            itemData.requestOrderList &&
            itemData.requestOrderList.length > 0
          "
          :connectDataList="itemData.requestOrderList"
          @onClickDetail="onClickDetail"
          @onClickClose="onClickCloseDetail"
        /> -->
      </div>
    </div>

    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
        <!-- <template v-if="ingPayment">
          <button
            type="button"
            class="btn_primary btn_large"
            @click="$windowOpen(`${computedPaymentPath}/${aprvlInfo.docNo}`)"
          >
            전자결재 - {{ aprvlInfo.docStatusName }}
          </button>
        </template>
        <template v-else>
          <button type="button" class="btn_primary btn_large" @click="onClickGotoPayment">
            전자결재 생성
          </button>
        </template> -->
      </div>
      <div class="area_right">
        <template v-if="$isUser">
          <template v-if="status !== 'T'">
            <button type="button" class="btn_fourthly btn_large" @click="onClickOrderPdfView">
              미리보기
            </button>
          </template>
          <template v-else>
            <button class="btn_fourthly btn_large" @click="onClickPreviewPop">미리보기</button>
          </template>
        </template>
        <template v-if="$isAdmin">
          <button
            v-if="status === 'T' && !itemData.contractId"
            class="btn_fifthly btn_large"
            @click="onClickOrderRemove"
          >
            삭제
          </button>
          <!-- <template v-if="status !== 'C'"> -->
          <template v-if="status !== 'T'">
            <button type="button" class="btn_fourthly btn_large" @click="onClickOrderPdfView">
              미리보기
            </button>
          </template>
          <template v-else>
            <button class="btn_fourthly btn_large" @click="onClickPreviewPop">미리보기</button>
          </template>

          <button
            v-if="status === 'G' || status === 'E' || status === 'D'"
            type="button"
            class="btn_secondary btn_large"
            @click="onClickCancel"
          >
            발행 취소
          </button>
          <button
            v-if="!itemData.contractId && status === 'T'"
            type="submit"
            class="btn_fourthly btn_large"
            @click="onClickModify"
          >
            수정
          </button>
          <button
            v-if="status === 'T' && !itemData.contractId"
            type="button"
            class="btn_primary btn_large"
            @click="onClickPopPublish"
          >
            발주요청
          </button>

          <template v-if="!itemData.contractId">
            <!-- 연결된 계약서가 없을떄만 보인다 -->
            <button
              v-if="status === 'C'"
              type="submit"
              class="btn_primary btn_large"
              @click="onClickOrderMiddleClose"
            >
              중도종결
            </button>
            <!-- <button
            v-if="status === 'C'"
            type="submit"
            class="btn_primary btn_large"
            @click="onClickChangeOrder"
          >
            변경발주
          </button> -->
          </template>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <PDF
        v-show="false"
        ref="pdfCheck"
        :src="pdfViewSrc"
        @error="pdfCheckError"
        @loaded="pdfCheckLoaded"
      ></PDF>
      <PurchaseRequestDetailPopup
        v-if="isPopDetail"
        :requestCid="requestCidSelected"
        @onClickClose="onClickCloseDetail"
        @alert="onAlert"
      />

      <AlertPopup
        v-if="isPopOrderCancel"
        alertText="해당 발주서 발행을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClosePop"
        @onClickPrimary="onClickDoCancel"
      />
      <AlertPopup
        v-if="isPopPublish"
        alertText="해당 발주서를 발행 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelInPopPublish"
        @onClickPrimary="onClickConfirmInPopPublish"
      />
      <TemplatePreviewPopup
        v-if="isTemplatePopup"
        :previewId="previewId"
        @onClickClose="onClickClosePreviewPopup"
      />
      <OrderReasonPopup
        v-if="isOrderReasonPopup"
        :changeReason.sync="changeReason"
        @onClickReasonClose="onClickReasonClose"
        @onClickReasonSubmit="onClickReasonSubmit"
      />
      <DirectorLdapPopup
        v-if="isPopLdap"
        @onClickSelect="onClickSelectLdap"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PDF from "vue-pdf";
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import TableView from "@/components/shared/tableView/TableView";
import FileView from "@/components/common/file/FileView";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import OrderInfo from "@/components/admin/order/view/OrderInfo";
import PurchaseRequestConnectView from "@/components/shared/purchaseRequest/PurchaseRequestConnectView";
import OrderMemo from "@/components/admin/order/view/OrderMemo";
// import PurchaseJiraConnectView from '@/components/shared/purchaseJira/PurchaseJiraConnectView'
import AnnounceInfo from "@/components/admin/order/view/AnnounceInfo";
import ManagerInfo from "@/components/admin/order/view/ManagerInfo";
import SupplyPartnerInfo from "@/components/admin/order/view/SupplyPartnerInfo";
import SupplyPartnerManagerInfo from "@/components/admin/order/view/SupplyPartnerManagerInfo";
import SupplyInfo from "@/components/admin/order/view/SupplyInfo";
import PaymentMethod from "@/components/admin/order/view/PaymentMethod";
import AddInfo from "@/components/admin/order/view/AddInfo";
import GoodsDeadlineHistory from "@/components/admin/order/view/GoodsDeadlineHistory";

import PaymentInfo from "@/components/admin/order/view/PaymentInfo";
import AttachFile from "@/components/admin/order/view/AttachFile";
import PurchaseRequestDetailPopup from "@/components/admin/purchaseRequest/popup/PurchaseRequestDetailPopup";

import OrderPrintPopup from "@/components/admin/order/popup/OrderPrintPopup";
import TemplatePreviewPopup from "@/components/shared/template/TemplatePreviewPopup.vue";
import OrderReasonPopup from "@/components/admin/order/popup/OrderReasonPopup.vue";

import SupplyInfoItemModel from "@/components/admin/order/write/SupplyInfoItemModel";
import DirectorLdapPopup from "@/components/admin/system/director/popup/DirectorLdapPopup";

import { dateToStringHasDiv } from "@/utils/dateUtils";
import { downloadPdf } from "@/utils/pdfUtils";
import { downloadZip } from "@/utils/zipUtils";
import { dateToyymmdd } from "@/utils/dateUtils";
import { downloadFile } from "@/utils/fileUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { ObjectURLPdf } from "@/utils/pdfUtils";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "OrderView",
  components: {
    PDF,
    PageWithLayout,
    TableViewWithTitle,
    Input,
    TableHead,
    Board,
    TableView,
    FileView,
    Sticky,
    AlertPopup,
    PurchaseRequestDetailPopup,
    // PurchaseRequestJiraDetailPopup,
    OrderPrintPopup,
    OrderReasonPopup,

    OrderInfo,
    PurchaseRequestConnectView,
    OrderMemo,
    // PurchaseJiraConnectView,
    AnnounceInfo,
    ManagerInfo,
    SupplyPartnerInfo,
    SupplyPartnerManagerInfo,
    SupplyInfo,
    DirectorLdapPopup,
    PaymentMethod,
    AddInfo,
    GoodsDeadlineHistory,
    PaymentInfo,
    AttachFile,
    TemplatePreviewPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      orderCid: "",
      status: "",
      itemData: {},
      templateId: "",
      templateName: "",

      isPopDetail: false,
      requestCidSelected: "",

      changeReason: "",
      postBoard: {
        purchasePersonList: [],
      },
      isPopOrderCancel: false,
      isPopLdap: false,
      isPopPublish: false,
      isPrintPopup: false,
      previewId: "",
      isTemplatePopup: false,

      resultId: "",
      showPostCid: true,
      isOrderReasonPopup: false,

      attachFile: {
        electronicDocumentFile: [], //전자서명파일
        purchaseOrderFile: [], //발주서
        estimateFile: [], //견적서
        contractFile: [], //계약서
        etcAttachFile: [], //기타파일
        advancePaymentSuretyAttachFile: [], //선급금이행증서
        contractSuretyAttachFile: [], //계약이행증서
        defectSuretyAttachFile: [], //하자이행증권
      },
      aprvlInfo: {
        docStatus: "",
        docStatusName: "",
        docNo: "",
      },
      pdfViewSrc: null,
    };
  },
  computed: {
    showDownload() {
      if (
        (this.itemData.status == "G" || this.itemData.status == "Y") &&
        this.itemData.resultId &&
        this.itemData.resultId != "-"
      )
        return true;
      return false;
    },
    computedPaymentPath() {
      const { docStatus } = this.aprvlInfo;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    ingPayment() {
      const { docStatus, docStatusName, docNo } = this.aprvlInfo;

      let ret = true;
      if (docStatus) {
        //전자결제 문서가 있을떄
        ret = true;
      } else {
        //전자결재 문서 안만들었을떄

        ret = false;
      }

      return ret;
    },
  },
  created() {
    this.orderCid = this.$route.params.id;
    this.getData();
  },
  methods: {
    async getData() {
      const path = `${this.$apiPath.ORDER}/${this.orderCid}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.itemData = data;
      this.templateId = this.itemData.templateId;
      this.templateName = this.itemData.templateName;

      const {
        companyInfo,
        requestOrderList,
        draftCid,
        draftTitle,
        orderGoodsRequests,
        currency,
        postTender,
        postBoard,
        attachFile,
      } = this.itemData;
      this.postBoard = postBoard;

      // this.connectDataList = requestOrderList || [];
      if (companyInfo) this.CompanyInfoModel.setData(companyInfo);

      if (draftCid) this.draftBudget.draftCid = draftCid;
      if (draftTitle) this.draftBudget.draftTitle = draftTitle;

      if (orderGoodsRequests) {
        this.supplyInfoModelList = orderGoodsRequests.map((item) => {
          const model = new SupplyInfoItemModel();

          const cloned = Object.assign({}, item, { currency: currency });

          model.setData(cloned);
          return model;
        });
      }

      if (this.itemData.resultId) {
        this.resultId = this.itemData.resultId;
      }

      this.status = this.itemData.status;

      if (this.itemData.aprvlInfo) {
        this.aprvlInfo = this.itemData.aprvlInfo;
      }
      this.attachFile = attachFile;
    },
    // 구매요청서 항목 클릭시
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    // 계약서 상세 연결
    onClickConnectContract(item) {
      const { contractId } = item;
      this.$windowOpen(`${this.$routerPath.CONTRACT_VIEW}/${item.contractId}`);
    },
    // 구매신청서 상세 팝업 열기
    onClickConnectRequestPost(id) {
      this.requestCidSelected = id;
      this.isPopDetail = true;
    },
    // 구매신청서 상세 팝업 닫기
    onClickCloseDetail() {
      this.requestCidSelected = "";
      this.isPopDetail = false;
      // this.isPopDetailJira = false;
    },
    // onClickDetailJira( id ){
    //   this.requestCidSelected = id;
    //   // this.isPopDetailJira = true;
    // },
    onClickCancelInPopPublish() {
      this.isPopPublish = false;
    },
    onClickPopPublish() {
      this.isPopPublish = true;
    },
    onAlert(text) {
      this.alert(text);
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.ORDER_LIST,
        query: this.$route.query,
      });
    },
    onClickCancel() {
      this.isPopOrderCancel = true;
    },
    onClickClosePop() {
      this.isPopOrderCancel = false;
    },
    async onClickDoCancel() {
      this.isPopOrderCancel = false;

      const path = `${this.$apiPath.ORDER}/${this.orderCid}/cancel`;

      const result = await ApiService.shared.putData(path, {});
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.orderCid = this.$route.params.id;

      this.getData();

      // this.getData();
      // this.$router.push({
      //   path: `${ this.$apiPath.ORDER_VIEW }/${ this.orderCid }`
      // });
    },
    async onClickPreviewPop() {
      if (!this.previewId) {
        const obj = this.itemData;
        const url = `${this.$apiPath.ORDER}/template/preview/${this.orderCid}`;
        const result = await ApiService.shared.postData(url, obj);
        const { code, data, text } = result;

        if (code !== "200") {
          this.alert(text);
          return;
        }
        this.previewId = data.previewId;
      }

      //this.$windowOpen(`/templatePreview?previewId=${this.previewId}`, 'width=1290');
      this.isTemplatePopup = true;
    },
    onClickClosePreviewPopup() {
      this.isTemplatePopup = false;
    },
    onClickConfirmInPopPublish() {
      this.isPopPublish = false;
      this.saveData();
    },
    async saveData(isTemp = false) {
      //      const alertMessage = this.getAlertMessage();

      // if (alertMessage) {
      //   this.alert(alertMessage);
      //   return;
      // }
      let path = `${this.$apiPath.ORDER}/${this.orderCid}`;
      if (isTemp) path += "?temp=Y";

      const objToSend = this.itemData;
      objToSend.orderCid = this.orderCid;

      await ApiService.shared.postData(path, objToSend).then((result) => {
        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }

        window.location.reload();
        return;
      });
    },

    onClickCloseOrderPreviewPop() {
      this.isPrintPopup = false;
    },
    onClickGotoPayment() {
      this.putPdfData();
    },
    async putPdfData() {
      const path = `${this.$apiPath.ORDER}/aprvl/${this.itemId}`;
      const obj = {
        ...this.itemData,
      };
      const result = await ApiService.shared.postData(path, obj);
      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text);
        return;
      }
      if (data) {
        this.getData(this.itemId);
      }
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.ORDER_MODIFY}/${this.orderCid}`,
      });
    },
    onClickOrderRemove() {
      const funcRemove = async () => {
        const path = `${this.$apiPath.ORDER}/${this.orderCid}`;
        const result = await ApiService.shared.deleteData(path);
        const { code, message } = result;
        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

          return;
        }
        this.$router.push({
          path: this.$routerPath.ORDER_LIST,
        });
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: "발주서를 삭제하시겠습니까?",
        onClickY: funcRemove,
      });
    },
    async onClickOrderPdfView() {
      if (this.attachFile?.electronicDocumentFile.length < 1) {
        this.pdfCheckError();
        return;
      }
      const { sysFilePath, orgFile, attachType } = this.attachFile.electronicDocumentFile[0];
      const arrayBuffer = await ApiService.shared.getFile(
        `${this.$apiPath.ATTACH_FILE}/${sysFilePath}`,
      );
      const objectUrl = ObjectURLPdf(arrayBuffer);
      this.pdfViewSrc = objectUrl;
    },
    pdfCheckLoaded() {
      this.$windowOpen(this.pdfViewSrc);
    },
    async pdfCheckError(error) {
      // const params = {
      //   text: `PDF 문서를 읽을수 없습니다.<br />PDF 문서를 다시 만드시겠습니까?`,
      //   onClickY: () => {
      //     this.pdfGenerator();
      //   },
      // };
      // this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      this.pdfGenerator();
    },
    async pdfGenerator() {
      const path = `${this.$apiPath.ORDER_PDF_GENERATOR}/${this.orderCid}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      window.location.reload();
    },

    onClickOrderMiddleClose() {
      const params = {
        text: `종도종결을 진행하시겠습니까?`,
        onClickY: () => {
          this.isOrderReasonPopup = true;
          this.contractType = "M";
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickReasonClose() {
      this.isOrderReasonPopup = false;
      this.changeReason = "";
    },
    onClickReasonSubmit() {
      if (!this.changeReason) {
        this.alert("중도종결 사유를 입력해주세요");
        return;
      }
      this.submitOrderMiddleClose();

      this.isOrderReasonPopup = false;
      this.changeReason = "";
    },
    async submitOrderMiddleClose() {
      const path = `${this.$apiPath.ORDER}/termination`;
      const obj = {
        orderCid: `${this.orderCid}`,
        changeReason: this.changeReason,
      };

      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.getData(this.orderCid);
    },
    onClickChangeOrder() {
      //변경계약
      const params = {
        text: `변경발주를 진행하시겠습니까?<br />변경발주은 바로 변경발주서를 작성 하셔야됩니다.`,
        onClickY: () => {
          this.$router.push({
            name: `${this.$routerPath.ORDER_RENEWAL}`,
            params: { orderCid: `${this.itemId}`, type: "renewal" },
          });
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickPopLdap() {
      this.isPopLdap = true;
    },
    onClickClosePopLdap() {
      this.isPopLdap = false;
    },
    onClickSelectLdap(rowData) {
      this.isPopLdap = false;
      const { accountId, personName, email, emailId, tel, mobilePhone } = rowData;
      this.postBoard.purchasePersonList.map((item) => {
        if (item.code === "I") {
          item.email = email || emailId;
          item.loginId = accountId;
          item.name = personName;
          item.tel = tel || mobilePhone;
        }
      });
    },
  },
};
</script>
