<template>
  <TableViewWithTitle title="첨부 파일">
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col />
    </template>
    <template v-slot:body>
      <tr>
        <th>견적서</th>
        <td>
          <FileView :dataList="attachFileList || []" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

export default {
  name: "AttachFile",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    attachFileList: Array,
  },
};
</script>
